const About = ({ review, headshot, paragraphs }) => (
  <section id="about" className="relative pv6">
    <div className="container">
      <h2 className="section-heading dot mb5 tc">Meet the Director</h2>

      <Review review={review} />
      <Headshot headshot={headshot} />
      <VisibleParagraphs paragraphs={paragraphs.slice(0, 2)} />

      <Collapse
        renderContent={() => <HiddenParagraphs paragraphs={paragraphs.slice(2)} />}
        renderToggle={(expanded, handleToggle) =>
          !expanded && <ReadMore handleToggle={handleToggle} />
        }
      />
    </div>

    {/* Convex (bulging) curve */}
    <ConvexSwoop up className="absolute top-0 left-0 transY--100 w-100 h1 white" />

    {/* Convex (bulging) curve */}
    <ConvexSwoop className="absolute bottom-0 left-0 transY-100 w-100 h1 white" />
  </section>
)

/*
 *
 * Review
 *
 */

const Review = ({ review }) => (
  <Quote
    quote={review.quotation}
    source={review.source}
    href={review.href}
    className="ml-auto mr-auto mb4 measure tc"
  />
)

/*
 *
 * Headshot
 *
 */

const Headshot = ({ headshot }) => (
  <Img
    fluid={headshot.image.childImageSharp.fluid}
    alt={headshot.alt}
    className="ml-auto mr-auto mt5 mb5 bw3 br-100 b--blue w-two-thirds"
    style={{ maxWidth: `11rem` }}
  />
)

/*
 *
 * Visible Paragraphs
 *
 */

const VisibleParagraphs = ({ paragraphs }) => {
  return paragraphs.map((paragraph, i) => {
    return (
      <p
        key={i}
        dangerouslySetInnerHTML={{ __html: paragraph }}
        className="ml-auto mr-auto mt3 measure-wide lh-copy"
      />
    )
  })
}

/*
 *
 * Hidden Paragraphs
 * 
 */

const HiddenParagraphs = ({ paragraphs }) => {
  return paragraphs.map((paragraph, i) => {
    return (
      <p
        key={i + 2} // index restarts at 0 after slice
        dangerouslySetInnerHTML={{ __html: paragraph }}
        className="ml-auto mr-auto mt3 measure-wide lh-copy"
      />
    )
  })
}

/*
 *
 * Read More
 *
 */

const ReadMore = ({ handleToggle }) => (
  <button onClick={handleToggle} className="link db ml-auto mr-auto mt5 fw7 ttu">
    Read More
  </button>
)

/*
 *
 * Imports & Exports
 * 
 */

import React from 'react'

import Collapse from '../components/Collapse'
import Img from '../components/Img'
import Quote from '../components/Quote'
import { ConvexSwoop } from '../components/Swoops'

export default About
