const Footer = ({ background, socialLinks }) => (
  <footer id="contact" className="relative pt5 tc open-sans mid-gray">
    <div className="container">
      <h2 className="section-heading dot mb4">Contact</h2>

      <p className="f3 mb4">
      For Professional Inquiries
      </p>

      <p className="mb4">
      Michael Moore Agency<br></br>
      450 West 24th Street, Suite 1C<br></br>
      New York, NY  10011
      </p>

      <p className="ultra mb1">Telephone</p>
      <Anchor href="tel:+12122210400" >+1 212 221 0400</Anchor>

      <p className="ultra mt4 mb1">Fax</p>
      <Anchor href="tel:+12128819492">+1 212 881 9492</Anchor>

      <p className="lh-copy mt4">
        Get in touch with Shawna and follow her on Social Media
      </p>

      <SocialLinks links={socialLinks} />

      <Copyright />
    </div>

    <Img
      fluid={background.image.childImageSharp.fluid}
      alt={background.alt}
      className="fill"
      style={{ position: `absolute`, zIndex: -1 }}
    />

    {/* Concave (collapsing) curve */}
    <ConcaveSwoop className="absolute top-0 left-0 w-100 h1 near-white" />
  </footer>
)

/*
 *
 * Social Links
 *
 */

const SocialLinks = ({ links }) => (
  <nav aria-label="Email and social media links" className="mt3 pt1">
    <ul className="sm:flex justify-center">
      {links.map((link, i) => (
        <SocialLink key={i} link={link.node} />
      ))}
    </ul>
  </nav>
)

/*
 *
 * Social Link
 *
 */

const SocialLink = ({ link }) => (
  <li className="flex-none sm:flex mt3 sm:mt0 sm:mh2">
    <Anchor href={link.href} className="heading link dib ttu">
      {link.text}
    </Anchor>
  </li>
)

/*
 *
 * Copyright
 * 
 */

const Copyright = () => (
  <p className="container pv4 ph3 lh-copy f6">
    &copy; {new Date().getFullYear()} Shawna Lucey. Brewed&nbsp;by&nbsp;
    <Anchor href="http://coffeeshopcreative.ca" className="link dib">
      Coffeeshop&nbsp;Creative
    </Anchor>
    .
  </p>
)

/*
 *
 * Imports & Exports
 * 
 */

import React from 'react'

import Anchor from '../components/Anchor'
import { ConcaveSwoop } from '../components/Swoops'
import Img from '../components/Img'

export default Footer
