const ComingUp = ({ productions }) => (
  <section id="coming-up" className="bg-gold pv6 white">
    <div className="container">
      <h2 className="section-heading tc">Coming Up...</h2>

      <EventsByUpcomingAndPast events={productions}>
        {(upcomingEvents, pastEvents) => (
          <Productions productions={upcomingEvents} />
        )}
      </EventsByUpcomingAndPast>
    </div>
  </section>
)

/*
 *
 * Productions
 *
 */

const Productions = ({ productions }) => (
  <ul className="coming-up-grid">
    {productions.map((production, i) => (
      <Production key={i} production={production.node} />
    ))}
  </ul>
)

/*
 *
 * Production
 *
 */

const Production = ({ production }) => {
  // In Coming Up, link to the show page instead of the company page if possible
  const link = production.showLink ? production.showLink : production.company.href

  return (
    <li className="coming-up-item-grid mt5 tc">
      <FirstDate day={production.firstDay} month={production.firstMonthAndYear} />
      <div>
        <Company href={link} text={production.company.text} />
        <Title lang={production.title.lang} text={production.title.text} />
        <Dates city={production.city} dates={production.dates} />
      </div>
    </li>
  )
}

const FirstDate = ({ day, month }) => (
  <div className="tc">
    <p data-detail="day" className="heading f1" style={{ lineHeight: 1.15 }}>
      {day}
    </p>
    <p data-detail="month" className="ttu b">
      {month}
    </p>
  </div>
)

const Company = ({ href, text }) => (
  <Anchor href={href} className="link-yellow dib mt3 f4 ttu">
    {text}
  </Anchor>
)

const Title = ({ lang, text }) => (
  <p lang={lang && lang} data-detail="title" className="pt2 pb1 lh-copy f4 i ttu">
    {text}
  </p>
)

const Dates = ({ city, dates }) => (
  <p data-detail="dates" className="o-90 lh-copy">
    {city}: {dates}
  </p>
)

/*
 *
 * Imports & Exports
 * 
 */

import React from 'react'

import Anchor from '../components/Anchor'
import EventsByUpcomingAndPast from '../components/EventsByUpcomingAndPast'

export default ComingUp
