const Hero = ({ background, tickets }) => (
  <section className="hero-spacing flex justify-center relative">
    <h2 className="sr-only">Images of some of Shawna's productions</h2>

    <Tickets tickets={tickets} />

    {/* Background image */}
    <Img
      fluid={background.image.childImageSharp.fluid}
      alt={background.alt}
      className="fill"
      style={{ position: `absolute`, zIndex: -2 }}
    />

    <Footlights n={48} />
  </section>
)

/*
 *
 * Tickets
 *
 */

class Tickets extends Component {
  state = { gsapReady: false, ticketRefs: [] }
  ticketRefs = []

  // When the ticketsRef array and GSAP are ready, trigger ticket animation
  componentDidMount = () =>
    this.ticketRefs[2] && loadjs.ready(`gsap`, () => this.fanTickets())

  fanTickets = () => {
    this.ticketRefs.map((ticket, i) =>
      TweenLite.to(ticket, 2, {
        rotation: i * 20 - 25,
        ease: `Power4.easeInOut`
      })
    )
  }

  render() {
    const { tickets } = this.props

    return (
      <ul className="relative mt5 pb5 w-75" style={{ maxWidth: `20rem` }}>
        {tickets.map((ticket, i) => (
          <Ticket
            key={i}
            ref={el => (this.ticketRefs[i] = el)} // save ref to each ticket
            ticket={ticket.node}
            id={i + 1}
          />
        ))}
      </ul>
    )
  }
}

/*
 *
 * Ticket
 *
 */

const Ticket = React.forwardRef(({ ticket, id }, ref) => (
  <li
    ref={ref}
    className="absolute top-0 left-0 mt4 w-100"
    style={{ transformOrigin: `left` }}
  >
    {/* Ticket background */}
    {/* <Img
      fluid={ticket.ticket.childImageSharp.fluid}
      alt="Ticket background"
      className="fill"
      style={{ position: `absolute`, zIndex: -1 }}
    /> */}

    <TicketOutline color={ticket.color} id={id}>
      <Img
        fluid={ticket.image.childImageSharp.fluid}
        alt={ticket.alt}
        objPos={ticket.objPos}
        className="absolute top-50 left-50 bw3 br2 z-999 aspect-ratio"
        style={{
          width: `61%`,
          // height: `90%`,
          transform: `translate(-46.5%, 11%)`,
          paddingBottom: `40%`
        }}
      />
    </TicketOutline>
  </li>
))

const TicketOutline = ({ color, id, children }) => {
  let fill
  if (color === `yellow`) fill = `#F2CC50`
  if (color === `blue`) fill = `#61e2e0`
  if (color === `red`) fill = `#EC3124`

  return (
    <div
      className="relative aspect-ratio aspect-ratio--16x9 w-100"
      style={{ transform: `translateZ(0)` }} // needed to prevent Safari bug
    >
      {/* Notches */}
      <svg className="aspect-ratio--object absolute w-100 h-100">
        <mask id={`m-${id}`} fill="white">
          <rect id={`r-${id}`} width="100%" height="100%" />
          <circle id={`c-${id}`} r="18" fill="#000" />
          <use xlinkHref={`#c-${id}`} x="100%" />
          <use xlinkHref={`#c-${id}`} y="50%" />
          <use xlinkHref={`#c-${id}`} y="100%" />
          <use xlinkHref={`#c-${id}`} x="100%" y="50%" />
          <use xlinkHref={`#c-${id}`} x="100%" y="100%" />
        </mask>
        <use xlinkHref={`#r-${id}`} fill={fill} mask={`url(#m-${id})`} />
      </svg>

      {/* Admit One text */}
      <div className="absolute w-100 h-100">
        <span
          className="db absolute left-0 top-50 sm:left-1 lg:left-2 z-999 f6 fw7 ttu white"
          style={{ transform: `translate(0%, -50%) rotate(-90deg)` }}
        >
          Admit One
        </span>
      </div>

      {/* Ticket image */}
      {children}
    </div>
  )
}

/*
 *
 * Footlights
 *
 */

const Footlights = ({ n }) => (
  <ul
    aria-hidden="true"
    className="flex justify-center absolute bottom-0 left-0 right-0 bg-pink h3"
    style={{ transform: `translateY(40%)` }}
  >
    {Array.apply(null, { length: n }).map((e, i) => (
      <Footlight key={i} />
    ))}
  </ul>
)

/*
 *
 * Footlight
 *
 */

const Footlight = () => (
  <li
    className="flex-none relative w2 bg-pink"
    style={{ marginLeft: `0.75rem`, marginRight: `0.75rem`, height: `3rem` }}
  >
    <div
      className="absolute bottom-0 left-0 yellow-gradient w2"
      style={{ height: `2.75rem`, transform: `translateY(-1rem)` }}
    />
    <div className="absolute bottom-0 left-0 bg-transparent w2 h2" />
    <div className="absolute bottom-0 left-0 br-100 bg-gold w2 h2" />
  </li>
)

/*
 *
 * Imports & Exports
 * 
 */

import React, { Component } from 'react'
import loadjs from 'loadjs'

import Img from '../components/Img'

export default Hero
