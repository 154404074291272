const Productions = ({ productions }) => (
  <section id="productions" className="bg-near-white pt6 pb5">
    <Heading />

    {/* Which productions are in the past? */}
    <EventsByUpcomingAndPast events={productions}>
      {(upcomingEvents, pastEvents) => {
        // Figure out which seasons exist among the past events:
        const seasons = pastEvents.map(production => production.node.season)
        const uniqueSeasons = Array.from(new Set(seasons))

        // Create an array of objects that groups the productions by season:
        const productionsBySeason = []
        uniqueSeasons.forEach(season => {
          productionsBySeason.push({
            heading: season,
            productions: pastEvents.filter(
              production => production.node.season === season
            )
          })
        })

        // Render a separate production list for each season:
        return productionsBySeason.map((season, i) => (
          <ProductionsBySeason
            key={i}
            season={season.heading}
            productions={season.productions}
          />
        ))
      }}
    </EventsByUpcomingAndPast>
  </section>
)

/*
 *
 * Heading
 *
 */

const Heading = () => (
  <header className="container flex justify-center items-end pb4 sm:pb5">
    <Spotlight
      style={{ marginRight: `1.5rem`, transform: `translateY(.75rem) scaleX(-1)` }}
    />
    <h2 className="section-heading dot tc">Past Productions</h2>
    <Spotlight style={{ marginLeft: `1.5rem`, transform: `translateY(.75rem)` }} />
  </header>
)

/*
 *
 * Spotlight
 *
 */

class Spotlight extends Component {
  state = { isIE: false }

  componentDidMount = () => {
    if (is.ie()) this.setState({ isIE: true })
  }

  render() {
    const { style = {} } = this.props
    const { isIE } = this.state

    return (
      <Fragment>
        {isIE ? (
          <div />
        ) : (
          <div aria-hidden="true" className="dn sm:db relative w3" style={style}>
            <Reveal css={{ rotation: -50, transformOrigin: `85% 85%` }} reset={true}>
              <SpotlightLamp
                className="db"
                style={{ transform: `translateY(-5%)` }}
              />
            </Reveal>

            <SpotlightBase
              className="absolute bottom-0"
              style={{ right: `4%`, width: `23%` }}
            />
          </div>
        )}
      </Fragment>
    )
  }
}

/* 
 *
 * Productions By Season
 * 
 */

const ProductionsBySeason = ({ season, productions }) => (
  <Fragment>
    <div className="container">
      <h3
        className="heading mv4 br3 bg-gold pv2 ph3 white"
        style={{ maxWidth: `20rem` }}
      >
        {season} Productions
      </h3>
    </div>

    <ul>
      {productions.map(production => {
        return (
          <Production key={production.node.lastDate} production={production.node} />
        )
      })}
    </ul>
  </Fragment>
)

/* 
 *
 * Production
 * 
 */

// DOCS: https://github.com/muicss/loadjs#documentation
// DOCS: https://greensock.com/docs/TweenLite
// Forum: https://greensock.com/forums/topic/15749-gsap-with-create-react-app/

class Production extends Component {
  state = { expanded: false }
  // details = React.createRef()

  handleClick = () => (this.state.expanded ? this.collapse() : this.expand())
  handleKeyUp = e => e.key === `Enter` && this.handleClick()

  expand = () =>
    loadjs.ready(`gsap`, () => {
      // When expanding, set this immediately
      this.setState({ expanded: true })

      // Invalidate the temporary inline styles (which match the starting state for the animation and are added to prevent a flash of content in the ending position)
      this.productionDetails.removeAttribute(`style`)

      // Expand the section to its natural height
      TweenLite.fromTo(
        this.productionDetails,
        1,
        {
          height: 0,
          autoAlpha: 0
        },
        {
          height: this.productionDetails.offsetHeight,
          autoAlpha: 1,
          ease: `Power3.easeInOut`,
          // After expanding, allow height to adapt naturally when window is resized:
          onComplete: () => this.productionDetails.removeAttribute(`style`)
        }
      )
    })

  collapse = () =>
    loadjs.ready(`gsap`, () => {
      // Collapse the section to 0
      TweenLite.to(this.productionDetails, 1, {
        height: 0,
        autoAlpha: 0,
        ease: `Power3.easeInOut`,
        onComplete: () => this.setState({ expanded: false })
      })
    })

  render() {
    const { production } = this.props
    const { expanded } = this.state

    return (
      <li
        role="button"
        tabIndex="0"
        onClick={this.handleClick}
        onKeyUp={this.handleKeyUp}
        aria-expanded={expanded}
        className="relative cursor-pointer"
      >
        <span className="sr-only">
          Click or press enter to expand/collapse production details
        </span>

        <div className="pv4 bg-near-white hover:bg-light-gray animate">
          <Header>
            <Overview
              title={production.title}
              company={production.company}
              role={production.role}
            />
            <Icon expanded={expanded} />
          </Header>

          <div
            ref={el => (this.productionDetails = el)}
            className="relative z-2 overflow-hidden"
            style={{ height: 0 }}
          >
            <ProductionDetails production={production} />
          </div>
        </div>
      </li>
    )
  }
}

/* 
 *
 * Header
 * 
 */

const Header = ({ children }) => (
  <div className="container group flex justify-between items-start pv2">
    {children}
  </div>
)

const Overview = ({ title, company, role }) => (
  <div>
    <h3 lang={title.lang && title.lang} className="heading mb2 ml1 f3 ttu">
      {title.text}
    </h3>

    <p className="dib mr2 mb2 br3 bg-white pv1 ph2 fw7 ttu">{company.text}</p>
    <p className="dib mr2 mb2 br3 bg-white pv1 ph2 fw7 ttu">{role}</p>
  </div>
)

const Icon = ({ expanded }) => (
  <span
    aria-hidden="true"
    className={`heading dn md:db f1 animate${!expanded && ` o-0 group-hover:o-100`}`}
  >
    {expanded ? `-` : `+`}
  </span>
)

/*
 *
 * Production Details
 *
 */

class ProductionDetails extends Component {
  // Prevent link and button clicks from triggering click event handlers on parent components (allow clicks on other elements to pass through and trigger the collapse)
  handleClick = e =>
    (e.target.nodeName === `A` || e.target.nodeName === `BUTTON`) &&
    e.stopPropagation()

  handleKeyUp = e => e.key === `Enter` && e.stopPropagation()

  render() {
    const { production } = this.props

    return (
      <div
        onClick={this.handleClick}
        onKeyUp={this.handleKeyUp}
        className="productions-grid container pt4 lh-tall"
      >
        <Info>
          {production.details && <Details details={production.details} />}

          {/* Don't collapse the production when links in this section are clicked */}
          <nav aria-label="Links to company and reviews" className="fw7 ttu">
            <Anchor href={production.company.href} className="link-left dib">
              Visit Company Site
            </Anchor>

            {production.reviews && <Reviews reviews={production.reviews} />}
          </nav>
        </Info>

        {production.photos ? (
          <Photos items={production.photos} alts={production} />
        ) : (
          <div />
        )}
      </div>
    )
  }
}

/* 
 *
 * Info
 * 
 */

const Info = ({ children }) => <div>{children}</div>

const Details = ({ details }) => (
  <dl className="mb4">
    {details.map((detail, i) => {
      return (
        detail.name !== `Dates` && (
          <Fragment key={i}>
            <dt className="fl fw7">{detail.name}</dt>
            <dd lang={detail.lang && detail.lang}>
              &nbsp;/&nbsp;
              {detail.value}
            </dd>
          </Fragment>
        )
      )
    })}
  </dl>
)

const Reviews = ({ reviews }) => (
  <ul className="mb4">
    {reviews.map((review, i) => {
      return (
        <li key={i}>
          <Anchor href={review.href} className="link-left dib mt2">
            {review.text}
          </Anchor>
        </li>
      )
    })}
  </ul>
)
/*
 *
 * Photos
 *
 */

const Photos = ({ items, alts }) => {
  let limit = 3

  // Wait for the document to exist
  if (typeof window !== `undefined`) {
    // Create a test element to check for grid support
    const testEl = document.createElement(`div`)
    testEl.style.display = `grid`

    // If grid is supported, show more items initially on large screens
    if (testEl.style.display === `grid`) {
      if (window.matchMedia(`(min-width: 48em)`).matches) {
        limit = 4
      }
    }
  }

  return (
    <Limit items={items} limit={limit} increment={limit}>
      {(visibleItems, limited, handleLoadMore) => (
        <div>
          <Gallery items={items} visibleItems={visibleItems} alts={alts} />
          {limited && <LoadMore handleLoadMore={handleLoadMore} />}
        </div>
      )}
    </Limit>
  )
}

/*
 *
 * Gallery
 *
 */

const Gallery = ({ items, visibleItems, alts }) => (
  <ImageGalleryAndLightbox
    galleryImages={visibleItems}
    lightboxImages={items}
    renderGallery={(galleryImages, handleImageClick) => (
      <Thumbnails
        items={galleryImages}
        handleImageClick={handleImageClick}
        alts={alts}
      />
    )}
  />
)

/*
 *
 * Thumbnails
 *
 */

const Thumbnails = ({ items, alts, handleImageClick }) => (
  <ul className="productions-images-grid nt3 pt4">
    <TransitionGroup component={null}>
      {items.map((item, i) => (
        <Mount
          key={item.image.childImageSharp.thumbnail.src} // MUST be unique
          animateSpace={false}
          animateExit={false}
          enterTimeout={1000}
          exitTimeout={0}
        >
          <Thumbnail
            item={item}
            alt={alts}
            lightboxIndex={i}
            handleImageClick={handleImageClick}
          />
        </Mount>
      ))}
    </TransitionGroup>
  </ul>
)

/*
 *
 * Thumbnail
 *
 */

const Thumbnail = ({
  item,
  alt,
  lightboxIndex,
  handleImageClick,
  className = ``
}) => (
  <li className={`group relative mt3 ${className}`}>
    <Img
      fluid={item.image.childImageSharp.thumbnail}
      alt={
        alt &&
        `A scene from the ${alt.company.text} production of ${alt.title.text}.`
      }
      objPosition={item.objPosition}
      className="aspect-ratio aspect-ratio--1x1"
    />

    {/* Overlay + Lightbox trigger */}
    <button
      onClick={handleImageClick}
      value={lightboxIndex}
      data-detail="overlay"
      className="flex justify-center items-center absolute fill bg-transparent w-100 group-hover:bg-black-60 f4 md:f3 transparent group-hover:white animate"
    >
      View photo
    </button>
  </li>
)

/*
 *
 * Load More Button
 *
 */

const LoadMore = ({ handleLoadMore }) => (
  <button
    onClick={handleLoadMore}
    className="link db ml-auto mr-auto mv3 sm:mv4 ttu fw7 tc"
  >
    See more photos
  </button>
)

/*
 *
 * Imports & Exports
 * 
 */

import React, { Component, Fragment } from 'react'
import is from 'is_js'
import loadjs from 'loadjs'
import TransitionGroup from 'react-transition-group/TransitionGroup'

import { ReactComponent as SpotlightLamp } from '../svg/spotlight-lamp.svg'
import { ReactComponent as SpotlightBase } from '../svg/spotlight-base.svg'

import Anchor from '../components/Anchor'
import EventsByUpcomingAndPast from '../components/EventsByUpcomingAndPast'
import ImageGalleryAndLightbox from '../components/ImageGalleryAndLightbox'
import Img from '../components/Img'
import Limit from '../components/Limit'
import Mount from '../components/Mount'
import Reveal from '../components/Reveal'

export default Productions
