const IndexPage = ({ data }) => (
  <Base>
    <main id="main-content" tabIndex="-1" className="open-sans mid-gray">
      <Hero
        background={data.backgroundsYaml.sunbeams}
        tickets={data.allTicketsYaml.edges}
      />

      <ComingUp productions={data.allProductionsYaml.edges} />

      <About
        review={data.aboutYaml.review}
        headshot={data.aboutYaml.headshot}
        paragraphs={data.aboutYaml.paragraphs}
      />

      <Productions productions={data.allProductionsYaml.edges} />
    </main>
  </Base>
)

/*
 *
 * Queries
 * 
 */

export const query = graphql`
  query {
    backgroundsYaml {
      sunbeams {
        image {
          childImageSharp {
            fluid(maxWidth: 3554) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        alt
      }
    }
    allTicketsYaml {
      edges {
        node {
          image {
            childImageSharp {
              fluid(maxWidth: 226) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          alt
          objPos
          color
        }
      }
    }
    aboutYaml {
      review {
        quotation
        source
        href
      }
      headshot {
        image {
          childImageSharp {
            fluid(maxWidth: 175) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      paragraphs
    }
    allProductionsYaml {
      edges {
        node {
          dates
          firstDay
          firstMonthAndYear
          lastDate
          season
          title {
            text
            lang
          }
          showLink
          company {
            text
            href
          }
          city
          role
          details {
            name
            value
          }
          reviews {
            text
            href
          }
          photos {
            image {
              childImageSharp {
                thumbnail: fluid(maxWidth: 980) {
                  ...GatsbyImageSharpFluid_withWebp
                }
                lightbox: fixed(width: 1500) {
                  ...GatsbyImageSharpFixed_withWebp
                }
              }
            }
            alt
            caption
            objPosition
          }
        }
      }
    }
  }
`

/*
 *
 * Imports & Exports
 * 
 */

import React from 'react'
import { graphql } from 'gatsby'

import Hero from '../sections/Hero'
import ComingUp from '../sections/ComingUp'
import About from '../sections/About'
import Productions from '../sections/Productions'

import Base from '../components/Base'

export default IndexPage
