class Header extends Component {
  state = { isIE: false }

  componentDidMount = () => {
    if (is.ie()) this.setState({ isIE: true })
  }

  render() {
    const { navLinks } = this.props
    const { isIE } = this.state

    const logoStyle = isIE
      ? { maxWidth: `26rem`, height: `4rem` } // clamp logo height in IE (sigh...)
      : { maxWidth: `26rem` }

    return (
      <header id="top" className="absolute top-0 left-0 right-0 pt4 tc">
        <div className="header-grid container">
          <CannonAndOverlay navLinks={navLinks} />

          <h1 className="mt4">
            <span className="sr-only">Shawna Lucey</span>
            {/* <SVG src={logo} className="db ml-auto mr-auto" style={logoStyle} /> */}
            <Logo className="db ml-auto mr-auto" style={logoStyle} />
            <p className="heading mt2 f4 sm:f3 red">Opera &amp; Stage Direction</p>
          </h1>
        </div>
      </header>
    )
  }
}

/*
 *
 * Imports & Exports
 * 
 */

import React, { Component } from 'react'
import is from 'is_js'
import { ReactComponent as Logo } from '../svg/logo.svg'

import CannonAndOverlay from '../components/CannonAndOverlay'

export default Header
