class CannonAndOverlay extends Component {
  state = { menuOpen: false, isIE: false }

  // Bind modal to appElement (http://reactcommunity.org/react-modal/accessibility/)
  componentDidMount = () => {
    Modal.setAppElement(`#___gatsby`)
    if (is.ie()) this.setState({ isIE: true })
  }

  // If menu is closed, peek cannonball on hover
  handleCannonMouseEnter = () => {
    if (!this.state.menuOpen && !this.state.isIE) {
      loadjs.ready(`gsap`, () =>
        TweenLite.to(this.cannonball, 0.5, { x: 0.5, y: 3 })
      )
    }
  }

  // If menu is closed, hide cannonball after hover
  handleCannonMouseLeave = () => {
    if (!this.state.menuOpen && !this.state.isIE) {
      loadjs.ready(`gsap`, () => TweenLite.to(this.cannonball, 0.5, { x: 4, y: 8 }))
    }
  }

  handleCannonClick = () => {
    const { menuOpen } = this.state
    loadjs.ready(`gsap`, () => (menuOpen ? this.closeMenu() : this.openMenu()))
  }

  openMenu = () => {
    noScroll.on()

    if (!this.state.isIE) {
      TweenLite.to(this.cannonball, 1, {
        x: -20,
        y: -20,
        ease: `Power3.easeOut`
      })
    }

    this.setState({ menuOpen: true })
  }

  closeMenu = () => {
    noScroll.off()

    if (!this.state.isIE) TweenLite.to(this.cannonball, 0.5, { x: 4, y: 8 })

    this.setState({ menuOpen: false })
  }

  render() {
    const { menuOpen, isIE } = this.state
    const { navLinks } = this.props

    return (
      <Fragment>
        {/* Menu toggle (cannon) */}
        <button
          ref={el => (this.burger = el)}
          onMouseEnter={this.handleCannonMouseEnter}
          onMouseLeave={this.handleCannonMouseLeave}
          onClick={this.handleCannonClick}
          className="relative z-max"
        >
          {/* Cannonball (animates on hover and click) */}
          {!isIE && (
            <span
              ref={el => (this.cannonball = el)}
              className="db absolute top-0 left-0 z--1"
              style={{ width: `28%`, transform: `translate(4px, 8px)` }}
            >
              <Cannonball className="db" />
            </span>
          )}

          {/* Cannon (doesn't animate) */}
          <Cannon className="db" style={{ width: `54px`, height: `52px` }} />
          {/* <SVG
            src={cannon}
            className="db"
            style={{ width: `54px`, height: `52px` }}
          /> */}

          <span className="heading ttu red" style={{ fontSize: `.9rem` }}>
            Menu
          </span>
        </button>

        {/* Menu content (hidden by default) */}
        <Modal
          isOpen={menuOpen}
          onRequestClose={this.handleCannonClick}
          closeTimeoutMS={500} // match exit animation timing
          overlayClassName="menu-modal-overlay fixed fill"
          className="menu-modal-content absolute fill overflow-auto scrolling-touch"
        >
          <MenuContent navLinks={navLinks} closeMenu={this.handleCannonClick} />
        </Modal>
      </Fragment>
    )
  }
}

/*
 *
 * Menu Content
 * 
 */

const MenuContent = ({ navLinks, closeMenu }) => (
  <aside
    onClick={closeMenu}
    className="pt6 w-100 h-100 mid-gray"
    style={{ backgroundColor: `rgba(249, 241, 228, 0.96)` }}
  >
    <nav
      aria-label="Main navigation"
      className="ml-auto mr-auto sm:pt4"
      style={{ maxWidth: `35rem` }}
    >
      <ul className="flex flex-column items-start mt4 container">
        {navLinks.map((link, i) => (
          <Reveal key={i} css={{ y: 20, autoAlpha: 0 }} delay={i * 0.1 + 0.1}>
            <li key={i} className="dib link-left mb4 sm:pb2 f3 sm:f2">
              <ScrollTo href={link.node.href} className="heading dot db lh-solid">
                {link.node.text}
              </ScrollTo>
            </li>
          </Reveal>
        ))}
      </ul>
    </nav>
  </aside>
)

/*
 *
 * Imports & Exports
 * 
 */

import React, { Component, Fragment } from 'react'
import is from 'is_js'
import loadjs from 'loadjs'
import Modal from 'react-modal'
import noScroll from 'no-scroll'

import { ReactComponent as Cannon } from '../svg/cannon.svg'
import { ReactComponent as Cannonball } from '../svg/cannonball.svg'

import Reveal from '../components/Reveal'
import ScrollTo from '../components/ScrollTo'

export default CannonAndOverlay
